<template>
    <!-- 评估 -->
  <div class="assessment">
    
    <div class="">
        <AssessmentVue></AssessmentVue>
    </div>
    <div class="btn1">
            <span class="span2" @click="toProgramme">返回服务内容</span>
    </div>
  </div>
</template>

<script>
import AssessmentVue from './short/Assessment.vue'
export default {
    components: { AssessmentVue },
    methods: {
        
        toProgramme() {
            this.$router.push({
                path: '/enterprise/programme',
                query: {
                    id:this.$route.query.id
                }            
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.assessment{
     padding: 30px 0px;
    //  .btn {
    //     cursor: pointer;
    //     margin-bottom: 15px;
    //     font-weight: 600;
    //     color: var(--headFont-color);
    //     width: 110px;
    //     height: 32px;
    //     text-align: center;
    //     line-height: 32px;
    //     background: #FFFFFF;
    //     border: 1px solid var(--custom-color);
    // }
}

</style>